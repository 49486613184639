import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query,Timestamp, updateDoc, doc, setDoc, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import swal from 'sweetalert';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from '../LocationContext'; 

function InvoicesComp() {
  // States
  const [courseDetails, setCourseDetails] = useState({
    feeDeposit: 0,
    feeInstallments: 0,
    mcoursePrice: 0,
    mcourseDuration: 0,
  }); 
  const [amountPaid, setAmountPaid] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [amountPaidThisMonth, setAmountPaidThisMonth] = useState(0);
  const [totalRemainingBalance, setTotalRemainingBalance] = useState(0); // New state for total remaining balance
  const [theCourseName, setThecourseName] = useState("");
  const [studentStatus, setstudentStatus] = useState("");
  const [thecohort, setCohort] = useState("");
  const [programDuration, settheDuration] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [bio, setBio] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [thePhoneNumber, setPhoneNumber] = useState("");
  const [userId, setUserId] = useState(null);

  const auth = getAuth();
  const [amountToPay, setAmountToPay] = useState(0); // Amount to be paid
  const [selectedMonth, setSelectedMonth] = useState(""); // Selected month for payment


  //
  const { location, currency } = useLocation(); 
  const [loggedInUserIpAddress, setloggedInUserIpAddress] = useState('');
  const [currentCurrencyCode, setcurrentCurrencyCode] = useState('USD');
  const [conversionRate, setConversionRate] = useState(1); // Default rate (USD to USD = 1)


  useEffect(() => {
      fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => setloggedInUserIpAddress(data.ip))
          .catch(error => console.error('Error fetching IP address:', error));
  }, []); // The empty array ensures this effect runs only once, on component mount.

  useEffect(() => {
    if (location && currency) {
      if (location.country === "KE") {
        setcurrentCurrencyCode("KES");
        setConversionRate(1); // Assuming KES to KES is 1:1
      } else {
        setcurrentCurrencyCode("USD");
        // Fetch the conversion rate dynamically if needed (hardcoding for now)
        setConversionRate(0.00779); // Example: 1 KES = 0.007 USD
      }
    }
  }, [location, currency]);
  

  // Handle Make Payment
  const handleMakePayment = (expectedAmount, paidAmount, month) => {
    const balance = expectedAmount - paidAmount; // Calculate remaining balance
    setAmountToPay(balance); // Set the balance as the amount to pay
    setSelectedMonth(month); // Set the month for which the payment is being made
    //processPayment(balance);
  };

  const handleProceedToPayment = () => {
    console.log(`Amount to be paid for ${selectedMonth}: ${amountToPay}`);
    // You can perform additional actions like navigating to a payment gateway
    toast.info(`Initiating payment for ${selectedMonth}`);
    setIsLoading(true);

    let amountSentKES = amountToPay * 100;
    let amountSentUSD = amountSentKES * 0.00779;
    let finalAmout = amountToPay;
    let finalAmoutUSD = amountSentUSD;
    let displayAmount = 0;

    if(location.country === "KE"){

      displayAmount = amountToPay;

        const handler = window.PaystackPop.setup({
          key: 'pk_live_f75e7fc5c652583410d16789fc9955853373fc8c', // Your public key
          email: userEmail,
          amount: amountSentKES, // amount in kobo (100 KES)
          currency: "KES",
          callback: function(response) {
            //console.log(response)
            if (response.status === 'success') {
              // Proceed with your logic, e.g., updating the database or displaying a success message
              const payMethod = "Paystack - KES";
              const currency = "KES";
              const responseReference = response.reference;
              const responseTrans = response.trans; 
              processPayment(payMethod, currency,responseReference, responseTrans, selectedMonth, finalAmout, displayAmount );
              //toast.success(`Payment was successful, setting up your account`);
              
            } else {
                swal('Payment Error', 'Payment failed or was not successful', 'error');
                toast.success(`Payment was successful, setting up your account`);
                setIsLoading(false);
            }
          },
          onClose: function() {
            // Handle payment error
            swal('Payment Error', 'Transaction was not completed, action canceled', 'error');
            setIsLoading(false);
          }
        }); 
        // Open the Paystack payment modal
        handler.openIframe()
    }else{
      displayAmount = amountSentUSD/100;
        const handler = window.PaystackPop.setup({
          key: 'pk_live_f75e7fc5c652583410d16789fc9955853373fc8c', // Your public key
          email: userEmail,
          amount: finalAmoutUSD, // amount in kobo (100 KES)
          currency: "USD",
          callback: function(response) {
            //console.log(response)
            if (response.status === 'success') {
              // Proceed with your logic, e.g., updating the database or displaying a success message
              const payMethod = "Paystack - USD";
              const currency = "USD";
              const responseReference = response.reference;
              const responseTrans = response.trans; 
              processPayment(payMethod, currency,responseReference, responseTrans, selectedMonth, finalAmout, displayAmount);
              //toast.success(`Payment was successful, setting up your account`);
              
            } else {
                swal('Payment Error', 'Payment failed or was not successful', 'error');
                toast.success(`Payment was successful, setting up your account`);
                setIsLoading(false);
            }
          },
          onClose: function() {
            // Handle payment error
            swal('Payment Error', 'Transaction was not completed, action canceled', 'error');
            setIsLoading(false);
          }
        }); 
        // Open the Paystack payment modal
        handler.openIframe()
    };

  };

  /////

  const generateAdmissionNumber = () => {
    return Math.floor(100000 + Math.random() * 900000);
  };

  const processPayment = async (paymentMethod, currency,responseReference, responseTrans, selectedMonth, amount, displayAmount,  ) => {
    var timeStamp = new Date();
    toast.info(`setting up your account....`);
    
    // Define options for formatting the date
    var options = { 
        weekday: 'long', // Full name of the day
        day: 'numeric',  // Numeric day of the month
        month: 'long',   // Full name of the month
        year: 'numeric'  // Full year
    };

    // Format the timestamp using `toLocaleDateString`
    var paymentDate = timeStamp.toLocaleDateString('en-GB', options);  
    var invoiceNumber = Math.random();
    var invnu = invoiceNumber * 79710;
    var invnumb = Math.round(invnu);

    let incomeAmount =  amount;
    let studentsUserName = bio;
    let phoneNumber = thePhoneNumber;


    var sendEmail = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' +
            '<html xmlns:v="urn:schemas-microsoft-com:vml">' +
            '<head>' +
            '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />' +
            '<meta name="viewport" content="width=device-width; initial-scale=1.0; maximum-scale=1.0;" />' +
            '<link href="https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700" rel="stylesheet">' +
            '<link href="https://fonts.googleapis.com/css?family=Quicksand:300,400,700" rel="stylesheet">' +
            '<title>Payment Receipt</title>' +
            '<style type="text/css">' +
            'body {' +
            'width: 100%;' +
            'background-color: #ffffff;' +
            'margin: 0;' +
            'padding: 0;' +
            '-webkit-font-smoothing: antialiased;' +
            'mso-margin-top-alt: 0px;' +
            'mso-margin-bottom-alt: 0px;' +
            'mso-padding-alt: 0px 0px 0px 0px;' +
            '}' +
            'p, h1, h2, h3, h4 {' +
            'margin-top: 0;' +
            'margin-bottom: 0;' +
            'padding-top: 0;' +
            'padding-bottom: 0;' +
            '}' +
            'span.preheader {' +
            'display: none;' +
            'font-size: 1px;' +
            '}' +
            'html {' +
            'width: 100%;' +
            '}' +
            'table {' +
            'font-size: 14px;' +
            'border: 0;' +
            '}' +
            '@media only screen and (max-width: 640px) {' +
            '.main-header {' +
            'font-size: 20px !important;' +
            '}' +
            '.main-section-header {' +
            'font-size: 28px !important;' +
            '}' +
            '.show {' +
            'display: block !important;' +
            '}' +
            '.hide {' +
            'display: none !important;' +
            '}' +
            '.align-center {' +
            'text-align: center !important;' +
            '}' +
            '.no-bg {' +
            'background: none !important;' +
            '}' +
            '.main-image img {' +
            'width: 440px !important;' +
            'height: auto !important;' +
            '}' +
            '.divider img {' +
            'width: 440px !important;' +
            '}' +
            '.container590 {' +
            'width: 440px !important;' +
            '}' +
            '.container580 {' +
            'width: 400px !important;' +
            '}' +
            '.main-button {' +
            'width: 220px !important;' +
            '}' +
            '.section-img img {' +
            'width: 320px !important;' +
            'height: auto !important;' +
            '}' +
            '.team-img img {' +
            'width: 100% !important;' +
            'height: auto !important;' +
            '}' +
            '}' +
            '@media only screen and (max-width: 479px) {' +
            '.main-header {' +
            'font-size: 18px !important;' +
            '}' +
            '.main-section-header {' +
            'font-size: 26px !important;' +
            '}' +
            '.divider img {' +
            'width: 280px !important;' +
            '}' +
            '.container590 {' +
            'width: 280px !important;' +
            '}' +
            '.container580 {' +
            'width: 260px !important;' +
            '}' +
            '.section-img img {' +
            'width: 280px !important;' +
            'height: auto !important;' +
            '}' +
            '}' +
            '</style>' +
            '</head>' +
            '<body class="respond" leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">' +
            '<table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff">' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">' +
            '<tr>' +
            '<td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">' +
            '<tr>' +
            '<td align="center" height="70" style="height:70px;">' +
            '<a href="" style="display: block; border-style: none !important; border: 0 !important;"><img width="100" border="0" style="display: block; width: 150px;" src="https://ubunifucollege.com/assets/images/logo-main-black.png" alt="" /></a>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<table border="0" width="100%" cellpadding="0" cellspacing="0" bgcolor="ffffff" class="bg_color">' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" align="center" width="590" cellpadding="0" cellspacing="0" class="container590">' +
            '<tr>' +
            '<td height="20" style="font-size: 20px; line-height: 20px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="color: #343434; font-size: 24px; font-family: Quicksand, Calibri, sans-serif; font-weight:700; letter-spacing: 3px; line-height: 35px;" class="main-header">' +
            '<div style="line-height: 35px">PAYMENT RECEIPT</div>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" width="40" align="center" cellpadding="0" cellspacing="0" bgcolor="eeeeee">' +
            '<tr>' +
            '<td height="2" style="font-size: 2px; line-height: 2px;">&nbsp;</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="color: #888888; font-size: 18px; font-family: Work Sans, Calibri, sans-serif; line-height: 23px;" class="text_color">' +
            '<div style="color: #333333; font-size: 18px; font-family: Work Sans, Calibri, sans-serif; font-weight: 600; mso-line-height-rule: exactly; line-height: 23px;">' +
            'Receipt #' + invnumb + '' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" width="400" align="center" cellpadding="0" cellspacing="0" class="container590">' +
            '<tr>' +
            '<td align="center" style="color: #888888; font-size: 16px; font-family: Work Sans, Calibri, sans-serif; line-height: 24px;">' +
            '<div style="line-height: 24px">' +
            'for ' + studentsUserName + ' <br/>' +
            'Paid on ' + paymentDate + '' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="25" style="font-size: 25px; line-height: 25px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0; font-size: 10px;">' +
            '<table border="0" width="400" align="center" cellpadding="0" cellspacing="0" class="container590">' +
            '<tr>' +
            '<td align="center" style="color: #000; font-size: 18px; font-family: Work Sans, Calibri, sans-serif; line-height: 24px; padding-top:20px; padding-bottom:20px;">' +
            '<div style="line-height: 24px">' +
            'Payment Amount: ' +currency + '. ' + displayAmount + '' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="35" style="font-size: 25px; line-height: 25px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" width="400" align="center" cellpadding="0" cellspacing="0" class="container590">' +
            '<tr>' +
            '<td align="center" style="color: #888888; font-size: 16px; font-family: Work Sans, Calibri, sans-serif; line-height: 24px;">' +
            '<div style="line-height: 24px">' +
            'Payment Method: ' + paymentMethod + '' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="20" style="font-size: 25px; line-height: 25px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center">' +
            '<table border="0" align="center" width="160" cellpadding="0" cellspacing="0" bgcolor="5caad2">' +
            '<tr>' +
            '<td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>' +
            '</tr>' +
            '<tr>' +
            '<td align="center" style="color: #ffffff; font-size: 14px; font-family: Work Sans, Calibri, sans-serif; line-height: 26px;">' +
            '<div style="line-height: 26px;">' +
            '<a href="https://ubunifu.web.app/view-receipt?' + invnumb + '" style="color: #ffffff; text-decoration: none;">VIEW RECEIPT</a>' +
            '</div>' +
            '</td>' +
            '</tr>' +
            '<tr>' +
            '<td height="10" style="font-size: 10px; line-height: 10px;">&nbsp;</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '</body>' +
            '</html>';

     
    
    try {

      const newDataRef = doc(collection(db, "Income"));
      setDoc(newDataRef, {

            studentId: userId,
            incomeAmount: amount,
            paymentDate: Timestamp.now(),
            paymentMethod: paymentMethod,
            createdBy: auth.currentUser.uid,
            timeCreated: Timestamp.now(),
            paymentId:newDataRef.id,
            invoiceNumber:invnumb,
            currency,
            responseReference, 
            responseTrans, 
            selectedMonth,
            displayAmount

      }).then(()=>{

        const newMail = doc(collection(db, "mail"));
        setDoc(newMail, {
          to: userEmail,
          message: {
          subject: 'Ubunifu College Payment Receipt #'+invnumb+'',
          text: 'Hi  user, ',
          html: sendEmail, 
          }
        });

        }).then(()=>{
            //if the student is already admitted dont send an admission letter
            toast.info(`setting up your account....`);
            sendSMS(studentsUserName, amount, paymentDate, invnumb, phoneNumber, currency, displayAmount);
        })

    } catch (error) {
        console.error("Error adding document: ", error);
        swal('Error!', 'An error occured!', 'error');
    }
};

const sendSMS = async (studentName, amount, paymentDate, receiptNumber, phoneNumber, currency, displayAmount) => {
  const message = `Dear ${studentName}, we have received your payment of ${currency}. ${displayAmount} on ${paymentDate} . View your receipt here https://ubunifu.web.app/view-receipt?${receiptNumber}. Thank you for your timely payment. - Ubunifu College`;
  const smsData = {
      phoneNumber: phoneNumber,
      message: message
  }; 

  try {
      const response = await fetch('https://us-central1-ubunifu-college.cloudfunctions.net/api/send-sms', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(smsData)
      });

      const data = await response.json();
      console.log(data);
      
      //
      if (studentStatus === "applicant"){
        handleAdmission();
        toast.info(`Please wait. Setting up your account....`);
      }else{
        swal("Success!", "Payment successfully received", "success");
        toast.success(`Payment successfully received`);
        window.location.reload();
      }

      return true;
  } catch (error) {
      console.error("Error sending SMS: ", error);
      if (studentStatus === "applicant"){
        handleAdmission();
        toast.info(`Please wait. Setting up your account....`);
      }else{
        swal("Success!", "Payment successfully received", "success");
        toast.success(`Payment successfully received`);
        window.location.reload();
      }
      return false;
  }
};


//end payment logic

const handleAdmission = async () => {

    const admissionNumber = generateAdmissionNumber();
    const mcourseDuration = courseDetails.mcourseDuration;
    const userName = bio;
    const thecourse = theCourseName;

    var admissionEmailTemplate = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Admission Notification</title>
        <style>
            body {
                width: 100%;
                background-color: #ffffff;
                margin: 0;
                padding: 0;
                font-family: Arial, sans-serif;
                -webkit-font-smoothing: antialiased;
                mso-margin-top-alt: 0px;
                mso-margin-bottom-alt: 0px;
                mso-padding-alt: 0px;
            }
            p, h1, h2, h3, h4 {
                margin: 0;
                padding: 0;
            }
            .header {
                text-align: left;
                padding: 20px;
            }
            .header img {
                width: 150px;
            }
            .main-content {
                width: 90%;
                max-width: 600px;
                margin: auto;
                padding: 20px;
            }
            .main-content h1 {
                color: #333;
                font-size: 24px;
            }
            .main-content p {
                color: #666;
                font-size: 16px;
                line-height: 1.5;
            }
            .footer {
                width: 90%;
                max-width: 600px;
                margin: auto;
                text-align: left;
                padding: 22px;
                font-size: 14px;
                color: #333;
            }
            .footer a {
                color: #1a73e8;
                font-family: Arial, sans-serif;
                text-decoration: none;
            }
            
            .footer img{
              height: 40px;
            }
        </style>
    </head>
    <body>
        <div class="header">

        </div>
        <div class="main-content">
            <p>Dear ${userName},</p><br/> 
        
            <p>We are pleased to inform you that you have been admitted to <strong>Ubunifu College</strong> to pursue studies in <strong>${thecourse}</strong> for the upcoming <strong>${programDuration}</strong>. Your admission number is <strong>${admissionNumber}</strong>.</p>
            <br/> 
        
            <p>At Ubunifu College, we are committed to providing you with the skills and knowledge necessary to excel in the rapidly evolving fields of <strong>data science</strong> and <strong>artificial intelligence</strong>. Our carefully designed curriculum ensures you are well-prepared to thrive in today’s technology-driven world.</p>
            <br/>
        
            <p><strong>What’s Next?</strong></p>
            <p>Your journey at Ubunifu College is about to begin! Here’s what to expect:</p>
            <br/>
                
            <p><strong>Orientation Day:</strong></p>
            <p>
                Join us for orientation any day starting tomorrow at <strong>2:00 PM East African Time</strong>, prior to the start of your classes.  
                <ul>
                    <li>For in-person orientation, visit our campus at <strong>Paramount Plaza, 4th Floor</strong>.</li>
                    <li>For virtual orientation, log into your student account and navigate to the <strong>“Orientation Menu”</strong> on the left panel.</li>
                </ul>
                During orientation, you will meet your instructors and fellow students and learn more about the exciting opportunities awaiting you.
            </p>
            <br/>
                
            <p><strong>Course Start Date:</strong></p>
            <p>Classes officially begin on <strong>${thecohort}</strong>. Please ensure you are ready to start this incredible learning journey.</p>
            <br/>
            
            <p>We are thrilled to welcome you to the <strong>Ubunifu College community</strong>. Should you have any questions or need further assistance, please reach out to our admissions team via email at <a href="mailto:admissions@ubunificollege.ac.ke">admissions@ubunificollege.ac.ke</a> or call us at <strong>+254 727 634 101</strong>.</p>
            <br/>
            
            <p>Congratulations once again on your admission. We wish you the very best in your studies and look forward to seeing you thrive!</p>
        </div>
        <div class="footer">
            <img src="https://ubunifucollege.com/assets/images/logo-main-black.png" alt="Ubunifu College">
            <p>Warm regards,</p>
            <p>Reinhard Omukuba<br>
            Chief Operations Officer<br>
            Ubunifu College<br>
            4th Floor Paramount Plaza,<br>
            Nairobi, Kenya,<br>
            0727634101</p>
        </div>
    </body>
    </html>
    `;

    try {
      await updateDoc(doc(db, 'users', userId), {
        userStatus: "Student",
        Status: "Active",
        admissionDate: Timestamp.now(),
        admissionNumber
      });
        const newMail = doc(collection(db, "mail"));
        setDoc(newMail, {
          to: userEmail, 
          message: {
          subject: '🎉 ADMISSION CONFIRMATION',
          text: 'Hi  user, ',
          html: admissionEmailTemplate, 
          }
        }).then(()=>{        
            swal("Success!", "Payment successfully received. Check your email for whats next.", "success");
            toast.success(`Payment successfully received. Check your email for whats next`);
            window.location.reload();
            //write here an actoion after an email has been delivered 
        })

    
    } catch (error) {
      console.error('Error updating document:', error);
      swal("Error", "An error occurred while admitting the student", "error");
    }
};



// READ PERSONAL DETAILS

  useEffect(() => {
    const fetchData = async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;

          setUserId(uid);

          
  
          // Fetch course details
          const userQuery = query(collection(db, "users"), where("userId", "==", uid));
          const userSnapshot = await getDocs(userQuery);
          let courseName = "";
          let bio = "";
          let userEmail = "";
          let phoneNumber = "";
          let userStatus = "";
          let Status = "";
          let cohort = "";
          
          userSnapshot.forEach((doc) => {
            courseName = doc.data().course;
            bio = doc.data().bio;
            userEmail = doc.data().userEmail;
            phoneNumber = doc.data().phoneNumber;
            userStatus = doc.data().userStatus;
            Status = doc.data().Status;
            cohort = doc.data().cohort;
          });

          setBio(bio);
          setUserEmail(userEmail);
          setPhoneNumber(phoneNumber);
          setThecourseName(courseName);
          setCohort(cohort);

          let studentAdmissionStatus = "applicant";
          if (userStatus === "Student" && Status === "Active"){
            studentAdmissionStatus = "student";
          }

          setstudentStatus(studentAdmissionStatus);


          
  
          const courseQuery = query(collection(db, "courses"), where("mcourseName", "==", courseName));
          const courseSnapshot = await getDocs(courseQuery);
  
          let courseData = {};
          let theDuration = "";
          courseSnapshot.forEach((doc) => {

            theDuration = doc.data().mcourseDuration;
            courseData = {
              feeDeposit: +doc.data().feeDeposit,
              feeInstallments: +doc.data().feeInstallments,
              mcoursePrice: +doc.data().mcoursePrice,
              mcourseDuration: +doc.data().courseDuration,
            };
          });
  
          setCourseDetails(courseData);
          settheDuration(theDuration);

          
  
          // Fetch payments
          const incomeQuery = query(collection(db, "Income"), where("studentId", "==", uid));
          const incomeSnapshot = await getDocs(incomeQuery);
  
          let totalPaid = 0;
          let paidThisMonth = 0;
          incomeSnapshot.forEach((doc) => {
            const amount = parseInt(doc.data().incomeAmount, 10);
            totalPaid += amount;
            const currentMonth = new Date().getMonth() + 1; // Current month
            const paymentDate = new Date(doc.data().paymentDate.seconds * 1000);
            if (paymentDate.getMonth() + 1 === currentMonth) {
              paidThisMonth += amount;
            }
          });
  
          setAmountPaid(totalPaid);
          setAmountPaidThisMonth(paidThisMonth);
  
          // Calculate the total remaining fee balance
          const totalCourseFee = courseData.mcoursePrice;
          const remainingBalance = totalCourseFee - totalPaid;
          setTotalRemainingBalance(remainingBalance);
  
          // Generate invoices dynamically based on course duration
          const invoicesData = [];
          let remainingFee = totalPaid;
          let carryOver = 0; // Track carry over from excess payments
  
          // Month 1 (Initial Deposit)
          const paidForMonth1 = Math.min(courseData.feeDeposit, totalPaid); // Cap the paid amount to the deposit
          invoicesData.push({
            month: "Month 1",
            expectedAmount: courseData.feeDeposit,
            paidAmount: paidForMonth1,
            status: paidForMonth1 >= courseData.feeDeposit ? "Paid" : "Pending", 
          });
  
          remainingFee -= paidForMonth1;
          carryOver = totalPaid > courseData.feeDeposit ? totalPaid - courseData.feeDeposit : 0; // Calculate excess for carryover
  
          // Generate remaining months invoices
          for (let month = 2; month <= courseData.mcourseDuration; month++) {
            const paidForMonth = Math.min(courseData.feeInstallments, carryOver); // Cap the paid amount for the month
            invoicesData.push({
              month: `Month ${month}`,
              expectedAmount: courseData.feeInstallments,
              paidAmount: paidForMonth,
              status: paidForMonth >= courseData.feeInstallments ? "Paid" : "Pending", 
            });
  
            carryOver -= paidForMonth; // Subtract the paid amount from carryover
          }
  
          setInvoices(invoicesData); 
        }
      });
    };
  
    fetchData();
  }, []);
  
  
  return (
    <div className="feeComp">
      <div className="feeTopDiv">
  <h6>Fee Payments</h6>

  <div className="feeItemsCont">
    <div className="feeItem">
      <div className='feeItemTop'>
        <FontAwesomeIcon icon={faCloud} className='' />
        <p>
          {new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(courseDetails.mcoursePrice * conversionRate)} {currentCurrencyCode}
        </p>
      </div>
      <p className="feeItemP">Current course fee</p>
    </div>
    <div className="feeItem">
      <div className='feeItemTop'>
        <FontAwesomeIcon icon={faCloud} className='' />
        <p>
          {new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(courseDetails.feeInstallments * conversionRate)} {currentCurrencyCode}
        </p>
      </div>
      <p className="feeItemP">Monthly Installments</p>
    </div>
    <div className="feeItem">
      <div className='feeItemTop'>
        <FontAwesomeIcon icon={faCloud} className='' />
        <p>
          {new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(amountPaid * conversionRate)} {currentCurrencyCode}
        </p>
      </div>
      <p className="feeItemP">Amount Paid</p>
    </div>
    <div className="feeItem">
      <div className='feeItemTop'>
        <FontAwesomeIcon icon={faCloud} className='' />
        <p>
          {new Intl.NumberFormat("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalRemainingBalance * conversionRate)} {currentCurrencyCode}
        </p>
      </div>
      <p className="feeItemP">Total Fee Balance</p>
    </div>
  </div>
</div>
 

      <h6 className="feeTransactionHeading">Invoices</h6>
      <div className="feeTableCont table-responsive">
        <Table striped className="feeTable">
          <thead>
            <tr>
              <th>Month</th>
              <th>Expected Amount</th>
              <th>Paid Amount</th>
              <th>Status</th>
              <th>Make Payment</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => (
              <tr key={index}>
                <td>{invoice.month}</td>
                <td>
                {(invoice.expectedAmount * conversionRate).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} {currentCurrencyCode}
                </td>
                <td>
                  {(invoice.paidAmount * conversionRate).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} {currentCurrencyCode}
                </td>

                <td>{invoice.status}</td>
                <td>
                {invoice.status !== "Paid" && (
                  <button 
                    className="btn btn-primary"
                    onClick={() => handleMakePayment(invoice.expectedAmount, invoice.paidAmount, invoice.month)}
                  >
                    Make Payment
                  </button>
                )}
              </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modal */}
      <Modal show={amountToPay > 0} onHide={() => setAmountToPay(0)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You need to pay  
            
          
            <strong>
            {(amountToPay * conversionRate).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} 
            </strong> 
          
           {currentCurrencyCode} for <strong>{selectedMonth}</strong>.</p>
          <p>Please proceed with the payment.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAmountToPay(0)}>
            Cancel Payment
          </Button>

          {isLoading ? (
                <Button variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                 Making Payments...
                </Button>
            ) : (
                <Button variant="primary" onClick={handleProceedToPayment}>
                Proceed to Payment
                </Button>
            )}

        </Modal.Footer>
      </Modal>

      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                transition={Bounce}  
              />

    </div>
  );
}

export default InvoicesComp;